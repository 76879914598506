<template>
  <div>
    <Header :current_document="current_document" :documents="documents" />
    <div class="body d-md-flex justify-content-between content-wrapper px-3">
      <div class="documents w-100">
        <div class="d-md-flex align-items-center justify-content-between">
          <h5>
            Documentos <small>({{ documents.length }})</small>
          </h5>
          <b-button
            @click="handleAddQuestions()"
            class="btn-add"
            v-if="!is_provider"
            :disabled="inRegister"
          >
            Adicionar
          </b-button>
        </div>
        <div class="documents-list mt-3 w-100">
          <skeleton
            height="80px"
            grid="1"
            gap="10"
            :rows="5"
            v-if="documents.length === 0 && !emptyDocuments"
          />
          <empty-list
            v-if="emptyDocuments && !inRegister"
            text="Não há perguntas cadastradas"
          />

          <b-card
            class="w-100 py-2 px-4 mb-4"
            border-variant="primary"
            no-body
            v-if="inRegister"
          >
            <strong class="text-primary d-block mb-3">Adicionar Documento</strong>
            <section
              class="
                d-block d-md-flex
                align-items-center
                justify-content-between
                flex-md-row
              "
            >
              <section class="w-100 mr-4 mb-3 mb-md-0">
                <span for="">Nome</span>
                <b-input
                  v-model="$v.newDocument.name.$model"
                  :class="!$v.newDocument.name.required && 'is-invalid'"
                  placeholder="Nome"
                />
                <b-form-invalid-feedback v-if="!$v.newDocument.name.required">
                  Esse campo é obrigatório
                </b-form-invalid-feedback>
              </section>
              <section class="mb-3 mb-md-0 w-100">
                <span for="">URL</span>
                <b-input
                  v-model="$v.newDocument.url.$model"
                  :class="!$v.newDocument.url.url ? 'is-invalid' : ''"
                  placeholder="URL"
                />
                <b-form-invalid-feedback v-if="!$v.newDocument.url.url">
                  Digite uma URL válida
                </b-form-invalid-feedback>
              </section>
            </section>
            <div
              class="icon-action text-center d-flex justify-content-end mt-3"
              v-if="!is_provider"
            >
              <b-button size="sm" variant="light" @click="handleCancelRegister()">
                Cancelar
              </b-button>
              <b-button
                size="sm"
                variant="primary"
                @click="handleUpdateQuestion(newDocument, 0)"
              >
                Salvar
              </b-button>
            </div>
          </b-card>

          <b-card
            v-for="(document, index) in documents"
            :key="index"
            class="w-100 py-2 px-4 mb-2"
            no-body
            v-disable="inRegister"
          >
            <section
              class="
                d-block d-md-flex
                align-items-center
                justify-content-between
                flex-md-row
              "
            >
              <section class="w-100 mr-4 mb-3 mb-md-0">
                <span for="">Nome</span>
                <b-input
                  class="mt-1"
                  :disabled="inRegister"
                  v-model="$v.documents.$each[index].name.$model"
                  placeholder="Nome"
                  :class="!$v.documents.$each[index].name.required && 'is-invalid'"
                  @change="handleUpdateQuestion(document, index)"
                />
                <b-form-invalid-feedback v-if="!$v.documents.$each[index].name.required">
                  Esse campo é obrigatório
                </b-form-invalid-feedback>
              </section>
              <section class="mb-3 mb-md-0 mr-md-4 w-100">
                <span for="">URL</span>
                <b-input
                  class="mt-1"
                  :disabled="inRegister"
                  :class="!$v.documents.$each[index].url.url ? 'is-invalid' : ''"
                  v-model="$v.documents.$each[index].url.$model"
                  placeholder="URL"
                  @change="handleUpdateQuestion(document, index)"
                />
                <b-form-invalid-feedback v-if="!$v.documents.$each[index].url.url">
                  Digite uma URL válida
                </b-form-invalid-feedback>
              </section>

              <span
                class="icon-action text-center d-block"
                v-if="!is_provider && !inRegister"
              >
                <b-spinner
                  v-if="selectedId === document.id"
                  type="grow"
                  small
                  class="h6 ml-3 mb-0"
                />
                <b-icon
                  v-else
                  @click="handleDeleteQuestion(document.id)"
                  v-b-popover.hover.top="'Excluir'"
                  class="h6 ml-3 pointer mb-0"
                  icon="trash"
                />
              </span>
            </section>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Header from "./Header";
import _ from "lodash";
import { validationMixin } from "vuelidate";
import { required, url } from "vuelidate/lib/validators";

export default {
  components: {
    Header
  },
  props: {
    current_document: {
      type: [Object],
      required: true,
    },
  },
  mixins: [validationMixin],
  validations: {
    newDocument: {
      name: {
        required
      },
      url: {
        url
      },
    },
    documents: {
      $each: {
        name: {
          required
        },
        url: {
          url
        }
      }
    }
  },

  data() {
    return {
      selectedId: null,
      inRegister: false,
      emptyDocuments: false,
      loading: false,
      selectedQuestionType: "",
      documentsTypes: [],
      documents: [],
      sourceOptions: [],
      document_id: null,
      newDocument: {
        name: "",
        url: "",
      },
    };
  },
  methods: {
    ...mapActions([
      "add_certificate",
      "get_all_certificates",
      "edit_certificates",
      "delete_certificate",
    ]),

    handleSelectQuestionType(qst) {
      this.selectedQuestionType = qst;
      this.handleGetQuestions();
      this.$refs.context.close();
    },

    handleAddQuestions() {
      this.inRegister = true;
      this.emptyDocuments = this.documents.length === 0;
    },
    handleCancelRegister() {
      this.inRegister = false;
      this.newDocument = {
        name: "",
        url: "",
      };
    },

    addQuestion(form) {
      this.add_certificate({ ...form }).then(() => {
        this.handleGetQuestions();
        this.handleCancelRegister();
      });
    },
    editQuestion(form) {
      this.edit_certificates({ ...form }).then(() => {
        this.handleGetQuestions();
        this.handleCancelRegister();
      });
    },
    update({ id, name, url }, order) {
      const data = {
        name,
        url: url,
        documenttype: this.current_document.id,
        order
      };

      if (id) {
        if (!this.$v.documents.$anyError){
          this.editQuestion({ ...data, id });
        }
      } else {
        if (!this.$v.newDocument.$anyError){
          this.addQuestion(data);
        }
      }
    },

    handleUpdateQuestion: _.debounce(function(value, order) {
      this.update(value, order);
    }, 500),

    handleGetQuestions() {
      this.loading = true;
      const documenttype = this.document_id;
      this.get_all_certificates({ documenttype }).then(({ data }) => {
        this.loading = false;
        this.emptyDocuments = data.results.length === 0;
        this.documents = data.results.map((question) => {
          return {
            ...question,
          };
        });
      });
    },
    handleDeleteQuestion(id) {
      this.selectedId = id;
      this.delete_certificate(id).then(() => {
        this.handleGetQuestions();
      });
    },
  },
  computed: {
    ...mapGetters(["is_provider"]),
  },
  mounted() {
    this.document_id = this.$route.params.id;
    this.handleGetQuestions();
  },
};
</script>
<style lang="scss" scoped>
.documents-type {
  strong {
    text-align: left;
    line-height: 1;
  }
}
</style>
